import { motion } from "framer-motion"
import React from "react"
import "./Btn.scss"

const Btn = props => {
  const { children, size = "md", color, fluid = false } = props
  return (
    <motion.button
      {...props}
      className={`btn 
      btn--${size} 
      ${color ? "btn--" + color : ""} 
      ${fluid ? "btn--fluid" : ""} 
      ${props.className ? props.className : ""}`}
    >
      {children}
    </motion.button>
  )
}

export default Btn
