import { motion } from "framer-motion"
import { useStaticQuery } from "gatsby"
import React from "react"
import SEO from "../seo"

import PricingHero from "./PricingHero"
import PricingPackages from "./PricingPackages"
import PricingStandAlone from "./PricingStandAlone"

const PricingPage = () => {
  const { pageTitle, heroImage, renderPackages } = usePricingPageQuery()
  return (
    <div>
      <SEO
        title="Pricing"
        description="View Max VR's package and standalone service prices."
      />
      <PricingHero pageTitle={pageTitle} heroImage={heroImage} />
      <PricingPackages renderPackages={renderPackages} />
      <PricingStandAlone />
    </div>
  )
}

export default PricingPage

const usePricingPageQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulPricingPage {
          pageTitle
          heroImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            file {
              contentType
              url
            }
            localFile {
              publicURL
            }
          }
          renderPackages {
            id
            packageName
            packageFeatures
            packagePrice
            packageMedia {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 360, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                publicURL
              }
              file {
                contentType
                url
              }
            }
          }
        }
      }
    `
  )
  return data.contentfulPricingPage
}
