import React from "react"
import "./PricingPackages.scss"
import Btn from "../Btn"
import { useStaticQuery } from "gatsby"
import PageTransitionLink from "../PageTransitionLink"
import { returnContentfulMediaType } from "../../utils/helpers"

import Img from "gatsby-image"

const MediaComponent = ({ media, className = "" }) => {
  const mediaType = returnContentfulMediaType(media)

  if (mediaType === "image") {
    return (
      <Img
        fluid={media.localFile.childImageSharp.fluid}
        style={{ position: "absolute" }}
        className={className}
      />
    )
  }
  if (mediaType === "video") {
    const videoUrl = media.localFile.publicURL
    return (
      <video
        // className="home-hero__video"
        src={videoUrl}
        autoPlay
        muted
        loop
        style={{ position: "absolute" }}
        onLoadedData={() => console.log("ready")}
        className={className}
      ></video>
    )
  }
  return <div>Media missing</div>
}

const Package = ({ renderPackage }) => {
  const {
    packageName,
    packageFeatures,
    packagePrice,
    packageMedia,
  } = renderPackage
  return (
    <div className="package">
      <div className="package__media">
        <MediaComponent media={packageMedia} className="h-full w-full" />
      </div>
      <h4 className="package__title">{packageName}</h4>
      {/* <p className="package__description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut.
      </p> */}
      <div className="package__features-container">
        <span>Features:</span>
        {packageFeatures.map(feature => (
          <div className="package__feature" key={feature}>
            · {feature}
          </div>
        ))}
      </div>
      <div className="package__bottom-content">
        <div className="package__price">${packagePrice}</div>
        <a
          href={`mailto:info@maxvr.co.nz?subject=Requesting Quote for: Max VR ${packageName}`}
          target="_blank"
        >
          <Btn fluid={true}>Select Package</Btn>
        </a>
      </div>
    </div>
  )
}

const PricingPackages = ({ renderPackages }) => {
  return (
    <section className="renders-packages container">
      <h2 className="renders-packages__title">Render Packages</h2>
      <p className="renders-packages__description">
        So you get the most value for your money, we’ve put together our most
        commonly requested render and VR packages.{" "}
      </p>
      <div className="renders-packages__package-container">
        {renderPackages.map(renderPackage => (
          <Package renderPackage={renderPackage} key={renderPackage.id} />
        ))}
      </div>

      <p className="centered-cta-line">
        For a custom quote, let us know your project and the types of services
        you require and we’ll get back to you asap -{" "}
        <a
          href={`mailto:info@maxvr.co.nz?subject=Requesting a Max VR Custom Quote`}
          target="_blank"
        >
          <span className="font-bold">Email us!</span>{" "}
        </a>
      </p>
    </section>
  )
}
export default PricingPackages
