import React from "react"
import "./PricingStandAlone.scss"
import Btn from "../Btn"
import { useStaticQuery } from "gatsby"

const standAloneItems = [
  {
    name: "Interior / Exterior 3D views ",
    details: "(including kitchen / landscaping)",
    pricing: "Starting from $200/view",
  },
  { name: "Cinematic Walk-through", pricing: "Starting from $500" },

  { name: "360 Images", pricing: "Starting from $300/room" },
  { name: "360 Walk-throughs", pricing: "Starting from $600" },
  { name: "VR ready model", pricing: "Starting from $1500" },
  { name: "VR Headset Studio session", pricing: "$150/hour" },
]

const StandAloneItem = ({ service }) => {
  const {
    standAloneServiceName,
    standAloneServiceDescription,
    standAloneServicePriceLine,
  } = service
  return (
    <div className="stand-alone-item">
      <div className="stand-alone-item__name">
        {standAloneServiceName}

        {standAloneServiceDescription && (
          <div className="stand-alone-item__details">
            {standAloneServiceDescription}
          </div>
        )}
      </div>

      <div className="stand-alone-item__pricing">
        <span>{standAloneServicePriceLine}</span>
      </div>
    </div>
  )
}

const PricingStandAlone = () => {
  const standAloneSerives = useStandAloneServicesQuery()
  return (
    <section className="pricing-stand-alone container">
      <h2 className="pricing-stand-alone__title">Stand Alone Services</h2>
      <div className="pricing-stand-alone__items-container">
        {standAloneSerives.map(service => (
          <StandAloneItem service={service} key={service.id} />
        ))}
      </div>

      <p className="mt-32 text-center">
        Please note: Our “starting from” pricing is based on a 3 bedroom, 1
        living room, single story townhouse with ArchiCAD files provided.
      </p>
    </section>
  )
}
export default PricingStandAlone

const useStandAloneServicesQuery = () => {
  const data = useStaticQuery(
    graphql`
      {
        allContentfulStandAloneService(
          sort: { order: ASC, fields: createdAt }
        ) {
          edges {
            node {
              id
              standAloneServiceDescription
              standAloneServiceName
              standAloneServicePriceLine
            }
          }
        }
      }
    `
  )
  return data.allContentfulStandAloneService.edges.map(edge => edge.node)
}
